import React, { useEffect, useState } from 'react';
import ActionButton from '../ActionButton/';

const InteractContainer = ({ readMessagesMutation, config, overwriteOpen = false, droplet, hideButton }) => {
  const {
    action: { autoOpen = false },
  } = config;
  const [open, setOpen] = useState(overwriteOpen || autoOpen || false);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    window.parent.postMessage(JSON.stringify({ action: open ? 'open' : 'close' }), '*');
    window.parent.postMessage(JSON.stringify({ action: 'read_messages' }), '*');
  }, [open]);

  useEffect(() => {
    window.addEventListener('message', event => {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.action === 'new_messages') return setNotificationCount(parsedData.payload.count);
        if (parsedData.action === 'read_messages') {
          return setNotificationCount(0);
        }
      } catch {}
    });
  }, []);

  return (
    <>
      <div
        id="button-container"
        style={{
          position: 'fixed',
          right: '0px',
          bottom: '0px',
          zIndex: '1400',
          height: '100%',
          width: '100%',
          ...(hideButton && { display: 'none' }),
        }}
      >
        <ActionButton
          droplet={droplet}
          onClick={() => {
            setOpen(!open);
          }}
          open={open}
          config={config}
        />
      </div>
      {!!notificationCount && !open && !hideButton && (
        <div
          id="webchat_client_interact_button_counter"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            background: 'red',
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            zIndex: 10000,
          }}
        >
          {notificationCount}
        </div>
      )}
    </>
  );
};

export default InteractContainer;
