/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { Transition } from 'react-spring';
import DefaultLogo from '../../assets/logo.png';

const ActionUI = styled.div`
  /* margin-left: 5px;
  margin-right: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: ${({ theme: { general } }) => general.boxShadow}; */

  width: 5rem;
  height: 5rem;

  color: ${({ theme: { header } }) => header.textColor};
  background: ${({ theme: { header } }) =>
    `linear-gradient(135deg,${header.background.color1} 0%,${header.background.color2} 100%)`};

  border-radius: ${({ droplet }) => (droplet ? '50% 50% 50% 0%' : '50%')};
  cursor: pointer;
`;

const Logo = styled.div`
  min-width: 5rem;
  min-height: 5rem;
  border-radius: ${({ droplet }) => (droplet ? '50% 50% 50% 0%' : '50%')};
  background: ${({ open, theme: { action } }) =>
    open ? '' : `url(${action.logo.url ? encodeURI(action.logo.url) : DefaultLogo})`};
  background-size: contain;
  background-repeat: no-repeat;
`;
export default ({ config, open, onClick, ...rest }) => {
  return (
    <ActionUI onClick={onClick} open={open} {...rest}>
      <Transition
        items={open}
        from={{ position: 'absolute', opacity: 0, transform: 'rotate(30deg)' }}
        enter={{ opacity: 1, transform: 'rotate(0deg)' }}
        leave={{ opacity: 0, transform: 'rotate(30deg)' }}
      >
        {open => (open ? props => <Close style={props} {...rest} /> : props => <Logo style={props} {...rest} />)}
      </Transition>
    </ActionUI>
  );
};
