/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core';
import { ThemeProvider as Provider } from 'emotion-theming';
import { Fragment } from 'react';

const base64ToObject = base64 => {
  try {
    return JSON.parse(atob(base64));
  } catch (e) {
    return { ERROR: 'JSON parse failed' };
  }
};

const urlObj = base64ToObject(window.location.pathname.split('/')[1] || {});

const width = urlObj.w;

let fontSize = '12px';
// if (width < 750) fontSize = '10px';
if (width < 380) fontSize = '10px';

const globalStyle = css`
  html,
  body {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  * {
    font-family: Proximus;
    font-size: ${fontSize};
    box-sizing: border-box;
  }
`;

const themeCreater = theme => ({
  general: {
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  },
  ...theme,
});
const ThemeProvider = ({ theme, children }) => {
  const config = themeCreater(theme);
  return (
    <Fragment>
      <style>{theme.css}</style>
      <Provider theme={config}>
        <Global styles={globalStyle} />
        {children}
      </Provider>
    </Fragment>
  );
};

export default ThemeProvider;
