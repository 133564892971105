import { InMemoryCache } from 'apollo-cache-inmemory/lib';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import React from 'react';
import { ApolloProvider, Mutation, Query } from 'react-apollo';
import ReactDOM from 'react-dom';
import ThemeProvider from './ThemeProvider';
import { INITIALIZE_WEBCHAT } from './actions/Config/index';
import InteractContainer from './components/InteractContainer';

const READ_MESSAGES = gql`
  mutation readMessages($channelId: ID!, $chatUserId: ID!) {
    readMessages(channelId: $channelId, chatUserId: $chatUserId)
  }
`;

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL });

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools: true,
});

const base64ToObject = base64 => {
  try {
    return JSON.parse(atob(base64));
  } catch (e) {
    return { ERROR: 'JSON parse failed' };
  }
};

const urlObj = base64ToObject(window.location.pathname.split('/')[1] || {});

const CHANNEL_ID = urlObj.channelId;
const OVERWRITE_OPEN = urlObj.open;
const DROPLET = urlObj.droplet;
const HIDE_BUTTON = urlObj.hideButton;
const chatUserId = urlObj.c;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Query query={INITIALIZE_WEBCHAT} variables={{ channelId: CHANNEL_ID }} fetchPolicy="network-only">
      {({ data, loading, error }) => {
        if (loading) {
          return console.log('Loading::', loading) || null;
        }
        if (error) {
          return console.log('Error::', error.message) || <span>ERROR:: {error.message}</span>;
        }
        if (!data) {
          return console.log('No data') || null;
        }

        return (
          <ThemeProvider theme={data.theme || {}}>
            <Mutation mutation={READ_MESSAGES}>
              {readMessagesMutation => (
                <InteractContainer
                  readMessagesMutation={readMessagesMutation}
                  droplet={DROPLET}
                  overwriteOpen={OVERWRITE_OPEN}
                  config={data.theme}
                  hideButton={HIDE_BUTTON}
                />
              )}
            </Mutation>
          </ThemeProvider>
        );
      }}
    </Query>
  </ApolloProvider>,
  document.getElementById('root')
);
